
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				$('.hamburger').on('click', function() {
					$(this).toggleClass('is-active');
					if ($(this).hasClass('is-active')) {
						$('.topnav').slideDown(500);
						$('body').addClass('menu-open');
					} else {
						$('.topnav').slideUp(500);
						$('body').removeClass('menu-open');
					}
				});
				$(window).resize(function(){
					$('.topnav').attr('style', '');
					$('.hamburger').removeClass('is-active');
				});

				// Submenu
				function openMenu(){
					$('.sub-menu').slideDown(300);
				}
				function closeMenu(){
					$('.sub-menu').slideUp(300);
				}
				function noMenu(){

				}
				function submenu(){
					$(".topnav .menu-item-has-children").hoverIntent(openMenu, closeMenu);
				}
				submenu();

				var resizeTimer;

				$('.quick-links a[href^="#"]').on('click', function(e) {
					e.preventDefault();
					var headerHt = $('header.header').outerHeight();
					var section = $(this).attr('href');
					$('body,html').animate({
						scrollTop: $(section).offset().top - headerHt
					}, 500);
				});

				$(window).on('resize', function(e) {
					clearTimeout(resizeTimer);
					resizeTimer = setTimeout(function() {
						submenu();
						if ($(window).innerWidth() < 768){
							$('.topnav .parent a').on('click', function(){
								if (!$(this).hasClass('open')){
									$(this).addClass('open');
									$(this).next().slideDown(300);
								} else {
									$(this).removeClass('open');
									$(this).next().slideUp(300);
								}
							});
						}
					}, 250);
				});

				$(document).one('ready', function() {
					$('.illust > div').addClass('animating');
				});

				// Forms
				(function() {
					$('form input, form textarea').on('focus', function(){
						$(this).prev().css('opacity', '1');
					});
					$('form input, form textarea').on('blur', function(){
						$(this).prev().css('opacity', '0');
					});
				})();

				$('.accordion-section h3').on('click', function() {
					$(this).toggleClass('open').siblings('h3').removeClass('open');
					if ($(this).hasClass('open')) {
						$(this).next().slideDown();
					} else {
						$(this).next().slideUp();
					}
					$(this).siblings('h3').next().slideUp();
				});

				$('.accordion-section h3:first-of-type').next().slideDown();

				$('.quick-links:not(.large, .legend) h4').on('click', function() {
					$(this).toggleClass('open');
					if ($(this).hasClass('open')) {
						$(this).next('ul').slideDown();
					} else {
						$(this).next('ul').slideUp();
					}
				});

				$('form input[type="file"]').each(function() {
          var upload = $(this);
          upload.wrap("<span class='upload-hide'></span>");
          var styledUpload = "<span class='styled-upload' data-value='"+upload.val().split('\\').pop()+"' data-name='"+upload.attr('name')+"'><span class='file'></span><span class='upload primary'>Choose File</span></span>";
          upload.parent('.upload-hide').after(styledUpload);
        });

        $('.styled-upload .upload').on('click', function () {
          var upload = $('input[type="file"][name="'+$(this).parent().attr('data-name')+'"]');
          upload.click();
        });

        $('form input[type="file"]').on('change', function () {
          var upload = $(this);
          var file = upload.val().split('\\').pop();
          if (file !== "") {
            upload.parent().next('.styled-upload').find('.file').text(file);
          } else {
            upload.parent().next('.styled-upload').find('.file').text("");
          }
        });

        $('.styled-upload .file').on('click', function() {
          var upload = $('input[type="file"][name="'+$(this).parent().attr('data-name')+'"]');
          upload.val("");
          upload.change();
        });
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		// Home page
		'home': {
			init: function() {
				var partnerSwiper = new Swiper('.partner-swiper', {
					slidesPerView: 5,
					speed: 500,
					autoplay: 1200,
					loop: true,
					autoplayDisableOnInteraction: false,
					breakpoints: {
						768: {
							slidesPerView: 3
						},
						568: {
							slidesPerView: 1
						}
					}
				});

				var testimonialSwiper = new Swiper('.testimonial-swiper', {
					loop: true,
					speed: 1000,
					autoplay: 5000
				});

				$('.partner-pagination .prev').on('click', function(e) {
					e.preventDefault();
					partnerSwiper.slidePrev();
				});

				$('.partner-pagination .next').on('click', function(e) {
					e.preventDefault();
					partnerSwiper.slideNext();
				});

				$('.testimonial-pagination .prev').on('click', function(e) {
					e.preventDefault();
					testimonialSwiper.slidePrev();
				});

				$('.testimonial-pagination .next').on('click', function(e) {
					e.preventDefault();
					testimonialSwiper.slideNext();
				});

				$(document).ready(function() {
					$('#map').vectorMap({
						map: 'usa_en',
						color: '#223745',
						backgroundColor: null,
						enableZoom: false,
						showTooltip: false,
						hoverColor: null,
						selectedColor: null,
						colors: stateArray
					});
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},

		// About us page, note the change from about-us to about_us.
		'single_team': {
			init: function() {
				$('ul.tabs li').on('click', function() {
					$(this).addClass('current').siblings().removeClass('current');
					var section = $(this).data('section');
					$('#'+section).addClass('show').siblings().removeClass('show');
				});
			}
		},
		'blog': {
			init: function() {
				$('.sidebar-list-section h3').on('click', function () {
					$(this).toggleClass('open');
					if ($(this).hasClass('open')) {
						$(this).next('ul').slideDown();
						$(this).parent().siblings().find('ul').slideUp();
					} else {
						$(this).next('ul').slideUp();
					}
				});
			}
		},
		'page_template_template_trends': {
			init: function() {
				var raw_data;
				var x = [];
				var y = [];
				var options = {};
				var graph_type;
				var yMaximum;
				var chart;
				var graph;
				var tmp;
				function wordSplit (value, index, values) {
					var sections = [];
					var words = value.split(" ");
					var temp = "";

					words.forEach(function(item, index) {
						if (temp.length > 0) {
							var concat = temp + ' ' + item;
							if (concat.length > 10) {
								sections.push(temp);
								temp = "";
							} else {
								if (index === (words.length-1)) {
									sections.push(concat);
									return;
								} else {
									temp = concat;
									return;
								}
							}
						}
						if (index === (words.length-1)) {
							sections.push(item);
							return;
						}
						if (item.length <= 10) {
							temp = item;
						} else {
							sections.push(item);
						}
					});
					return sections;
				}

				function moneyFormatter (value, index, values) {
					var formatter = new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 0,
					});
					return formatter.format(value);
				}

				for (var j = 0; j < trendsArray.length; j++) {
					for (var k = 0; k < trendsArray[j].graph.length; k++) {
						raw_data = trendsArray[j].graph[k].graph_data.split("\n");
						graph = trendsArray[j].graph[k];
						x = [];
						y = [];
						tmp = "";
						for (var i = 0; i < raw_data.length; i++) {
							if (i === 0) {
								continue;
							} else {
								tmp = raw_data[i].split(",");
								x.push(tmp[0]);
								y.push(tmp[1]);
							}
						}
						options = {
							type: graph.graph_type,
							data: {
								labels: x,
								datasets: [{
									data: y
								}]
							},
							options: {
								elements: {
									rectangle: {
										backgroundColor: 'rgba(153, 102, 51, .5)',
										borderWidth: 1,
										borderColor: '#BC5729'
									}
								},
								tooltips: {
									enabled: false
								},
								maintainAspectRatio: true,
								legend: {
									display: ((graph.graph_type === "pie") ? true : false)
								},
								title: {
									display: false
								},
								responsive: true
							}
						};
						if (graph.graph_type === "bar") {
							options.type = "bar";
						}
						if (graph.graph_type === "line") {
							options.data.datasets[0].borderColor = '#BC5729';
							options.data.datasets[0].backgroundColor = 'rgba(153, 102, 51, .5)';
							options.data.datasets[0].pointBackgroundColor = '#BC5729';
						}
						if (graph.graph_type === "pie") {
							options.data.datasets[0].backgroundColor = [
								'rgba(188, 87, 41, .5)',
								'rgba(166, 87, 41, .5)',
								'rgba(144, 77, 49, .5)',
								'rgba(122, 73, 53, .5)',
								'rgba(100, 68, 57, .5)',
								'rgba(78, 64, 61, .5)',
								'rgba(56, 59, 65, .5)',
								'rgba(34, 55, 69, .5)'
							];
							options.data.datasets[0].borderColor = [
								'#bc5729',
								'#a6522d',
								'#904d31',
								'#7a4935',
								'#644439',
								'#4e403d',
								'#383b41',
								'#223745'
							];
						}
						if (graph.graph_type !== "pie") {
							options.options.scales = {
								yAxes: [{
									ticks: {
										fontFamily: '"League Spartan", sans-serif',
										fontSize: 10,
										maxTicksLimit: 4
									}
								}],
								xAxes: [{
									color: '#334B59',
									ticks: {
										color: '#334B59',
										fontSize: 10,
										fontFamily: '"League Spartan", sans-serif',
										callback: wordSplit
									}
								}]
							};
							if (graph.no_grid_lines === true) {
								options.options.scales.xAxes[0].gridLines = {
									display: false
								};
								options.options.scales.yAxes[0].gridLines = {
									display: false
								};
							}
							if (graph.y_maximum !== "") {
								options.options.scales.yAxes[0].ticks.suggestedMax = graph.y_maximum;
								options.options.scales.yAxes[0].ticks.stepSize = Math.ceil(parseFloat(graph.y_maximum)/4);
							}
							if (graph.y_minimum !== "") {
								options.options.scales.yAxes[0].ticks.suggestedMin = graph.y_minimum;
							}
							if (graph.format_dollars === true) {
								options.options.scales.yAxes[0].ticks.callback = moneyFormatter;
							}
						}
						chart = new Chart($('#cvchart-'+j+'-'+k), options);
					}
				}
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
